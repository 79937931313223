
  import { defineComponent, ref } from 'vue'
  import useBBACPayment from "@/hooks/payment/bbac/useBBACPayment";
  import { toThousandsAndTwoDecimal } from '@/utils/payment/numberFormat'
  import { ColumnProps } from 'ant-design-vue/es/table/interface';
  import { useStore } from "vuex";
  import YearPicker from '@/views/Payment/components/YearPicker.vue'
  // import Pagination from "@/components/Pagination.vue";
  import TableWrapper from "@/components/TableWrapper.vue";
  type Key = ColumnProps['key'];
  const tableWidth = window.innerWidth
  const tableHeight = window.innerHeight-508
  import BuSelector from "@/views/Payment/components/BuSelector.vue";
  import EntitySelector from "@/views/Payment/components/EntitySelector.vue";
  import GetColumnWidth from "@/utils/payment/getColumnWidth";
  // const tableHeight = 104
  export default defineComponent({
    name: 'SalesIndex',
    components: {
      YearPicker,
      TableWrapper,
      BuSelector,
      EntitySelector
    },
    props: {},
    setup() {
      const store = useStore()
      const {
        searchParams, payoutRoundList, payoutCodeList, payoutStatusList,
        getInitCondition,
        columnsData, tableData, rowSelection, doSearch, doReset, pageChange, sizeChange, pageSize, pageNumber, total,
        downloadFilesClick,
        modalVisible, modalDownloadClick, modalLoading, modalColumnsData, downloadFileList, modalRowSelection,
        downloadParams, downloadRoundList
      } = useBBACPayment()
      getInitCondition()
      const handlePageChange = (page: number,pageSize: number) =>{
        pageChange(page, pageSize, rowSelection)
      }
      const handleSizeChange = (page: number,pageSize: number) =>{
        sizeChange(page, pageSize, rowSelection)
      }
      const downloadClose = () => {
        modalVisible.value = false
      }
      const rowClass = (record: unknown, index: number) => (index % 2 === 1 ? 'table-striped' : null)
      const tableWidth = ref(GetColumnWidth(columnsData));
      return {
        searchParams,
        payoutRoundList,
        payoutCodeList,
        payoutStatusList,
        doSearch,
        doReset,
        // tableWidth,
        tableHeight,
        columnsData,
        tableData,
        rowSelection,
        toThousandsAndTwoDecimal,
        pageSize,
        pageNumber,
        total,
        handlePageChange,
        handleSizeChange,
        store,
        downloadFilesClick,
        modalVisible,
        modalDownloadClick,
        modalLoading,
        modalRowSelection,
        modalColumnsData,
        downloadFileList,
        downloadParams,
        downloadRoundList,
        downloadClose,
        rowClass,
        tableWidth
      }
    }
  })
  